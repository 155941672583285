/* start custom scss snippet */

.article__content {
  code {
    font-size: 0.85rem;
  }

  blockquote {
    font-size: 1.7rem;
    font-style: italic;
    font-family: "Rockwell";
    color: #000;
  }
}

/* end custom scss snippet */
